import { useStoreInit } from "~/composables/useStoreInit";

const { loggedIn, user, session, fetch, clear } = useUserSession();

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { init } = useStoreInit();
  // Only run this middleware on the client-side
  if (process.server) return;

  // Token
  if (!loggedIn.value) {
    if (to.path !== "/auth/login" && to.path !== "/auth/login") {
      return navigateTo("/auth/login");
    } else {
      return;
    }
  }

  await init()

});
